.btn-wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;

  ::-webkit-file-upload-button {
    cursor: pointer;
  }

  .MuiButton-label {
    white-space: nowrap;
  }
  .MuiButton-root {
    width: 100%;
    color: #fff;
    background: #ff3a52;
    padding: 8px 15px;
    text-transform: initial;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 400;

    &:hover {
      background: #ff5c71;
    }
  }

  .MuiButton-text {
    font-family: 'Open Sans', roboto;
    font-weight: 600;
  }

  .MuiButton-textSecondary {
    background: #2f2834;

    &:hover {
      background: lighten(#2f2834, 15%);
    }
  }

  .Mui-disabled {
    background: rgba(0, 0, 0, 0.3);
  }
}

.btn {
  &--small .MuiButton-root {
    font-size: 11px;
    padding: 6px;
  }

  &--profile-actions .MuiButton-root {
    font-size: 14px;
    padding: 6px 12px;
    background: #2b282b;

    &:hover {
      background: #ff3a52;
    }
  }
}
