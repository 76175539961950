body {
  width: 100%;
  height: 100%;
  background-color: #f5f6fa !important;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  font-weight: normal;
  margin: 0;
  list-style-type: none;
}

ul {
  list-style: none;
}

ul,
figure {
  margin: 0;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swal2-container {
  z-index: 1301 !important;
}

.MuiDialog-paper,
.MuiDialogContent-root {
  overflow-y: unset !important;
}

article {
  ul {
    list-style: inherit;
  }

  pre {
    margin: 0;
  }

  a {
    cursor: pointer;
    color: #5e93c5;
    &:hover {
      color: #7eadda;
    }
  }

  blockquote {
    background: #eee;
    padding: 10px;
    border-radius: 5px;
    margin: 0;
  }
}

::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.MuiTabs-indicator {
  background-color: #06c68f !important;
}

.MuiTabs-root {
  min-height: 40px !important;
  min-width: 160px !important;
}

.Mui-checked + .MuiSwitch-track {
  background-color: #06c68f !important;
}

.MuiTab-root.Mui-selected,
.MuiSvgIcon-root.Mui-active,
.MuiSvgIcon-root.Mui-checked,
.MuiSvgIcon-root.Mui-selected,
.MuiSvgIcon-root.Mui-completed,
.MuiSwitch-thumb {
  color: #06c68f !important;
}

.DraftEditor-root {
  max-height: 210px !important;
}

.fireQuestionIsUsedInQuizPopup {
  font-size: 1.5em;
  font-weight: 450;

  br:last-child,
  br:nth-child(2) {
    display: block !important;
    content: '';
    margin-bottom: 15px;
  }
}
.swal2-icon {
  scale: 0.7;
}
.swal2-actions {
  font-size: 85%;
}

.custom-swal2-vertical-buttons .swal2-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.custom-swal2-button {
  width: 70%;
  margin: 5px 0;
}

.MuiTab-root {
  min-width: 160px !important;
  text-transform: capitalize !important;
  color: #000 !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  font-size: 14px !important;
  min-height: 40px !important;

  &[aria-selected='true'] {
    font-weight: 600 !important;
  }

  &.Mui-disabled {
    font-weight: 300 !important;
  }
}
