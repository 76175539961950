.thumbnail {
  display: flex;
  transition: transform 225ms ease-out;
  padding: 10px;
  &--row {
    align-items: center;
  }

  &--column {
    align-items: center;
    flex-direction: column;
  }

  &__image {
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 16px;
    color: #eee;
  }

  &--small {
    padding: 0;

    .thumbnail__title {
      font-size: 14px;
      margin-left: 8px;
    }
  }
}

.opened {
  transform: translateX(0);
}

.closed {
  transform: translateX(-120px);
}
